import { toHTML } from "@portabletext/to-html";
import {
  AnnouncementProps,
  AnnouncementBarProps,
} from "@bluebottlecoffee/design-system/components";
import {
  Announcement as AnnouncementSchema,
  AnnouncementBar as AnnouncementBarSchema,
} from "../sanity-schema";

function validate(data: AnnouncementBarSchema) {
  if (!data) {
    throw new Error("No data provided");
  } else if (!data.announcements || data.announcements.length === 0) {
    throw new Error("No announcements provided");
  }
}

function toAnnouncementProps(
  data: AnnouncementSchema,
  lang: string,
): AnnouncementProps {
  return {
    announcement: {
      text: toHTML(data.text[lang]),
      ...(data.detailsModals?.length && {
        detailsModals: data.detailsModals.map((modal) => ({
          details: modal.modalContent.map((content) => ({
            heading: content.heading[lang],
            description: toHTML(content.description[lang]),
          })),
          modalTitle: modal.modalTriggerText[lang],
        })),
      }),
    },
  };
}

export function toAnnouncementBarProps(
  data: AnnouncementBarSchema,
  lang: string,
): AnnouncementBarProps {
  validate(data);
  return {
    announcements: data.announcements.map((announcement) =>
      toAnnouncementProps(announcement, lang),
    ),
    ...(data.barColor && { barColor: data.barColor }),
  };
}

import {
  ChangeEvent,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import cookie from "js-cookie";
import Head from "next/head";
import {
  AnnouncementBar,
  BackToTopButton,
  Country,
  CountrySelector,
  CountrySelectorProps,
  DialogProps,
  InfoBlock,
  LayoutWithNav,
  LoginFormCopy,
  Nav,
  NavProps,
} from "@bluebottlecoffee/design-system/components";
import { useAnalytics, useCart } from "@chordcommerce/react-autonomy";
import { useRouter } from "next/router";
import { kebabCase } from "lodash";
import { toNavAndCartInfoBannerProps } from "../lib/transformers/info-block";
import {
  toCookiesNoticeProps,
  toDescriptionAddendumProps,
} from "../lib/transformers/cookies-notice";
import { BaseProps } from "../lib/sanity/shared";
import { toAnnouncementBarProps } from "../lib/transformers/announcement-bar";
import { toFooterProps } from "../lib/transformers/footer";
import { toNavProps } from "../lib/transformers/nav";
import { LoginDialog } from "./LoginDialog";
import { homePage } from "../lib/link-builders";
import { toMetaTagProps } from "../lib/transformers/metaTag";
import {
  Cart,
  ImageWithAltText,
  MetaTags as MetaTagsSchema,
} from "../lib/sanity-schema";
import { ConsentManagerCopyProps } from "./ConsentManagerWrapper";
import { nestlePrivacyToggle } from "./nestlePrivacyToggle";
import { handleEmailOptIn } from "../lib/component-utils/email-opt-in";
import { SearchDialog, SearchDialogCopy } from "./SearchDialog";
import { transformProductRecsToShopCards } from "../lib/transformers/product-recs";
import { productRecsWithBestValueVariant } from "../lib/utils/product-recs/product-recs";
import localization, { Region } from "../localization";
import { isFeatureEnabled } from "../lib/utils/is-feature-enabled";
import { CartReminder } from "./CartReminder";

type LayoutWrapperProps = PropsWithChildren<
  Omit<BaseProps, "appPlayLinks" | "flavorProfileCopy"> & {
    altBBLogo?: ImageWithAltText;
    consentManagerCopy: ConsentManagerCopyProps;
    isTransparent?: boolean;
    loginDialogCopy: LoginFormCopy;
    metaTags?: MetaTagsSchema;
    pageTitle?: string;
    productSearch: SearchDialogCopy;
    slideOutContent?: ReactNode[];
    cart?: Cart;
    openMiniCartPanel?: boolean;
  }
>;

export type BaseNavProps = Omit<NavProps, "brandLink">;

export const LayoutWrapper: FunctionComponent<LayoutWrapperProps> = ({
  accountMenuCopy,
  altBBLogo,
  announcementBar,
  aria,
  children,
  cookiesNotice,
  footer,
  isTransparent,
  lang,
  loginDialogCopy,
  metaTags,
  nav,
  navAndCartInfoBanner,
  pageTitle,
  productRecs,
  productSearch,
  region,
  slideOutContent,
  cart: cartCopy,
  openMiniCartPanel,
}) => {
  const {
    backToTopAriaLabel,
    brandLinkLabel,
    cartLinkLabel,
    skipToContent,
    skipToCookieNotice,
  } = aria;
  const { cart, forgetCart, loadCart } = useCart();
  const { trackProductClicked } = useAnalytics();

  const [cookiesNoticeCookie, setCookiesNoticeCookie] = useState<boolean>(true);
  const [infoBannerCookie, setInfoBannerCookie] = useState<boolean>(true);

  useEffect(() => {
    loadCart();
  }, [loadCart]);

  /** use state to delay cookie notice dialog & info banner render until .get */
  useEffect(() => {
    const hasPrivacyCookie = cookie.get("cookies_notice_dismissed") === "true";
    const hasInfoBannerCookie = cookie.get("info_banner_dismissed") === "true";
    setCookiesNoticeCookie(hasPrivacyCookie);
    setInfoBannerCookie(hasInfoBannerCookie);
  }, []);

  const cartItemCount =
    cart?.lineItems?.reduce((result, li) => result + li.quantity, 0) ?? 0;

  // Create a set of footerProps
  const footerProps = toFooterProps(footer, region, lang, (email) =>
    handleEmailOptIn(email),
  );

  // Cookies notice cookie & props
  const setPrivacyCookie = () => {
    cookie.set("cookies_notice_dismissed", "true");
  };

  const cookiesNoticeProps: DialogProps = {
    ...toCookiesNoticeProps({ data: cookiesNotice, lang }),
    ...(cookiesNotice.descriptionAddendum?.clickableText[lang] && {
      descriptionAddendum: {
        ...toDescriptionAddendumProps({
          data: cookiesNotice.descriptionAddendum,
          lang,
        }),
        onClick: async () => {
          const { openConsentManager } = await import(
            "@segment/consent-manager"
          );
          openConsentManager();
        },
      },
    }),
    isOpen: !cookiesNoticeCookie,
    onSubmit: () => {
      setPrivacyCookie();
      setCookiesNoticeCookie(true);
    },
  };

  // Info banner dismiss handler (set cookie)
  const handleInfoBannerDismiss = () => {
    cookie.set("info_banner_dismissed", "true");
    setInfoBannerCookie(true);
  };

  // Skip link props
  const skipToContentProps = {
    linkToId: "main",
    text: skipToContent ?? "Skip to content",
  };
  const skipToCookieNoticeProps = {
    linkToId: `${kebabCase(cookiesNotice.heading[lang])}-dialog`,
    text: skipToCookieNotice ?? "Skip to cookie notice",
  };
  const transformedSkipLinks = cookiesNoticeCookie
    ? [skipToContentProps]
    : [skipToContentProps, skipToCookieNoticeProps];

  // Push a "Do Not Sell My Personal Information" link onto the finePrint array
  footerProps.finePrint.push({
    url: "#",
    isFunctionLink: true,
    onClick: async () => {
      const { openConsentManager } = await import("@segment/consent-manager");
      openConsentManager();
    },
    text: `Your Privacy Choices ${nestlePrivacyToggle}`,
  });

  const transformedProductRecs = transformProductRecsToShopCards(
    productRecsWithBestValueVariant(productRecs, lang),
    lang,
    region,
    (product) => trackProductClicked(product),
  );

  const { asPath } = useRouter();
  const isAcountPage = asPath.includes("/account");

  const countries: CountrySelectorProps["options"] = localization
    .regionNames()
    .map((regionName) => ({
      value: regionName,
      display: regionName,
      ariaLabel: localization.regionLongName(regionName),
    }));

  const onSelectCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value as Region;
    const defaultLanguage = localization.defaultLanguage(selectedCountry);
    // clears cart data from the storefront's state and local storage
    forgetCart();
    switch (selectedCountry) {
      case "jp":
        window.location.href = "https://store.bluebottlecoffee.jp/";
        break;
      case "kr":
        window.location.href = "https://kr.bluebottlecoffee.com/";
        break;
      case "ca":
      case "us":
      default:
        // cookie will be checked by url resolver, and needs to be uppercase to match
        // https://github.com/bluebottlecoffee/worker-region-router/blob/main/src/url-resolver.js#L8
        cookie.set("selectedCountry", selectedCountry.toUpperCase());
        window.location.href = `/${selectedCountry}/${defaultLanguage.code}`;
    }
  };

  const countrySelectorProps = {
    defaultSelectedOption: region as Country,
    options: countries,
    onSelect: onSelectCountry,
  };

  const isCountrySelectorEnabled = isFeatureEnabled(
    process.env.NEXT_PUBLIC_ENABLE_COUNTRY_SELECTOR,
  );

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        {metaTags?.map((tag) => <meta {...toMetaTagProps(tag, lang)} />)}
      </Head>

      <LayoutWithNav
        transparentNav={isTransparent && nav.transparentMode}
        announcements={
          announcementBar?.announcements?.length > 0 && (
            <AnnouncementBar
              {...toAnnouncementBarProps(announcementBar, lang)}
              sideContent={
                isCountrySelectorEnabled ? (
                  <CountrySelector {...countrySelectorProps} />
                ) : null
              }
            />
          )
        }
        infoBanner={
          !infoBannerCookie &&
          navAndCartInfoBanner &&
          navAndCartInfoBanner.showInfoBanners && (
            <InfoBlock
              {...toNavAndCartInfoBannerProps({
                data: navAndCartInfoBanner,
                lang,
              })}
              centered
              dismiss={{
                ariaLabel: navAndCartInfoBanner.dismissAriaLabel[lang],
                onDismiss: handleInfoBannerDismiss,
              }}
            />
          )
        }
        nav={
          nav?.navItems?.length > 0 && (
            <Nav
              {...toNavProps(
                isTransparent,
                { cartItemCount, cartLinkLabel },
                nav,
                region,
                lang,
                altBBLogo,
              )}
              ariaLabels={aria}
              brandLink={{
                text: brandLinkLabel,
                url: homePage({ region, lang }),
              }}
              skipLinks={transformedSkipLinks}
              countrySelector={
                isCountrySelectorEnabled ? countrySelectorProps : undefined
              }
              openMiniCartPanel={
                isFeatureEnabled(process.env.NEXT_PUBLIC_ENABLE_ONLY_SIDECART)
                  ? openMiniCartPanel
                  : false
              }
            />
          )
        }
        cartReminder={<CartReminder cartCopy={cartCopy} lang={lang} />}
        sticky="navAndAnnouncements"
        bgColor="cream"
        footer={footerProps}
        cookiesNotice={cookiesNoticeProps}
      >
        <>
          {[
            ...slideOutContent,
            <LoginDialog
              copy={loginDialogCopy}
              accountMenuCopy={accountMenuCopy}
              region={region}
              lang={lang}
            />,
            <SearchDialog
              {...productSearch}
              lang={lang}
              region={region}
              noResultsShopCards={transformedProductRecs}
            />,
            !isAcountPage && (
              <BackToTopButton
                ariaLabel={backToTopAriaLabel}
                topThreshold={870}
                botThreshold={160}
              />
            ),
          ]}
          {children}
        </>
      </LayoutWithNav>
    </>
  );
};

LayoutWrapper.defaultProps = {
  altBBLogo: undefined,
  isTransparent: false,
  metaTags: [],
  pageTitle: "Blue Bottle Coffee",
  slideOutContent: [],
  cart: undefined,
  openMiniCartPanel: false,
};
